import $http from '@/utils/http.js';

/**
 * 主页banner数据
 * @param data
 */
export function getMainBannerData(data) {
    return $http.get('/Content/homepage_banner_index', data, 'loadingDiv');
}

/**
 * 主页banner添加操作
 * @param data
 */
export function mainBannerAddAction(data) {
    return $http.post('/Content/homepage_banner_add', data, 'loadingDiv');
}

/**
 * 主页banner保存修改操作
 * @param data
 */
export function mainBannerSaveModifyAction(data) {
    return $http.post('/Content/homepage_banner_edit', data, 'loadingDiv', 'multipart/form-data;charset=utf-8');
}
