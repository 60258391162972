<template>
    <div class="main-banner-page">
        <div class="header-wrap">
            <el-button size="small" type="primary" @click="handleAddNewBannerBtn">添加新BANNER</el-button>
        </div>
        <el-form class="main-banner-form" label-width="100px" label-position="left"
            v-for="(item, index) in mainBannerData.mainBannerForm" :key="`${item.id}_${index}`" :model="mainBannerData"
            :ref="item.formRef">
            <div class="content-wrap" style="background-color: #fff; margin: 20px; border-radius: 10px">
                <el-row>
                    <el-col :span="12">
                        <div class="content-left">
                            <el-form-item :prop="`mainBannerForm[${index}].title`" :rules="[
                                {
                                    required: true,
                                    message: '主页BANNER标题不能为空',
                                    trigger: 'blur'
                                }
                            ]">
                                <div>主页BANNER标题：</div>
                                <el-input size="small" style="width: 500px" maxlength="16" v-model="item.title"
                                    placeholder="请输入主页BANNER标题内容" v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.title }}</div>
                            </el-form-item>
                            <el-form-item :prop="`mainBannerForm[${index}].subtitle`" :rules="[
                                {
                                    required: true,
                                    message: '主页BANNER副标题不能为空',
                                    trigger: 'blur'
                                }
                            ]">
                                <div>主页BANNER副标题：</div>
                                <el-input size="small" style="width: 500px" maxlength="100" v-model="item.subtitle"
                                    placeholder="请输入主页BANNER副标题内容" v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.subtitle }}</div>
                            </el-form-item>
                            <!--  -->
                            <el-form-item :prop="`mainBannerForm[${index}].link`" :rules="[
                                {
                                    required: true,
                                    message: '跳转链接不能为空',
                                    trigger: 'blur'
                                },
                                {
                                    pattern:
                                        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/,
                                    message: '跳转链接格式不正确',
                                    trigger: 'blur'
                                }
                            ]">
                                <div>跳转链接：</div>
                                <el-input size="small" style="width: 500px" v-model="item.link" placeholder="请输入跳转链接"
                                    v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.link }}</div>
                            </el-form-item>
                            <el-form-item :prop="`mainBannerForm[${index}].sort`" :rules="[
                                {
                                    required: true,
                                    message: '排序序号不能为空',
                                    trigger: 'blur'
                                },
                                {
                                    pattern: /^[1-9][0-9]*$/,
                                    message: '排序只能填写不能以0开头的数字',
                                    trigger: 'blur'
                                }
                            ]">
                                <div>BANNER排序：</div>
                                <el-input size="small" style="width: 500px" v-model="item.sort"
                                    placeholder="请输入BANNER排序序号" v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.sort }}</div>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :prop="`mainBannerForm[${index}].status`" :rules="[
                            {
                                required: true,
                                message: 'BANNER状态不能为空',
                                trigger: 'change'
                            }
                        ]">
                            <div>BANNER状态：</div>
                            <el-radio-group v-model="item.status">
                                <el-radio v-for="item in statusList" :key="item.id" :label="item.id">{{
                                item.name
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :prop="`mainBannerForm[${index}].picture`" :rules="[
                            {
                                required: true,
                                message: '图片上传不能为空',
                                trigger: 'change'
                            }
                        ]">
                            <div>图片上传：</div>
                            <el-upload class="avatar-uploader" :ref="`uploadMainBannerPhoto${index}`" action="#"
                                :auto-upload="false" :show-file-list="false"
                                :on-change="handleMainBannerPictureSuccess.bind(null, { index: index, data: item })">
                                <img v-if="item.picture" :src="item.picture" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="picture-desc">
                                <p>Banner上传尺寸为1920*740px；</p>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="content-right" @click="handleSaveProcessBtn(item)" v-if="item.isProcessModify">
                            <el-button type="text">保存</el-button>
                        </div>
                        <div class="content-right" @click="handleModifyProcessBtn(item)" v-else>
                            <i class="el-icon-edit-outline size-icon"></i>
                            修改
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import jsonTool from '@/utils/jsonTool.js';
import {
    getMainBannerData,
    mainBannerAddAction,
    mainBannerSaveModifyAction
} from '@/common/api/contentManage/mainBanner.js';
export default {
    data() {
        return {
            // 状态列表
            statusList: [
                {
                    id: 0,
                    name: '禁用'
                },
                {
                    id: 1,
                    name: '启用'
                }
            ],
            mainBannerData: {
                mainBannerForm: [{}]
            }
        };
    },
    created() {
        this.queryMainBannerData();
    },
    methods: {
        queryMainBannerData() {
            getMainBannerData()
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        let newResult = jsonTool(result);
                        this.mainBannerData.mainBannerForm = newResult.map(item => {
                            return {
                                id: item.id,
                                title: item.mainTitle,
                                subtitle: item.subTitle,
                                link: item.jumpLink,
                                sort: item.sort,
                                status: item.isEnable,
                                picture: item.picture,
                                isProcessModify: item.isProcessModify,
                                flieList: [],
                                formRef: 'formref' + item.id
                            };
                        });
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理添加新banner按钮
        handleAddNewBannerBtn() {
            let params = {
                main_title: null,
                sub_title: null,
                jump_link: null,
                sort: null,
                is_enable: null,
                picture: null
            };
            if (this.mainBannerData.mainBannerForm.length < 5) {
                mainBannerAddAction(params)
                    .then(res => {
                        let { code, msg } = res;
                        if (code === 200) {
                            this.$message({
                                type: 'success',
                                message: `${msg}`,
                                center: true
                            });
                            this.queryMainBannerData();
                        }
                    })
                    .catch(err => {
                        // console.log(err);
                    });
            } else {
                this.$message({
                    type: 'warning',
                    message: '新banner最多只能添加5条',
                    center: true
                });
            }
        },
        handleMainBannerPictureSuccess(obj, file, fileList) {
            let index = obj.index;
            this.checkImgSize(file).then(data => {
                if (data) {
                    this.mainBannerData.mainBannerForm[index].picture = URL.createObjectURL(file.raw);
                    this.mainBannerData.mainBannerForm[index].fileList = file.raw;
                }
            });
            this.$refs[`uploadMainBannerPhoto${index}`].clearFiles();
        },
        checkImgSize(file) {
            return new Promise(function (resolve, reject) {
                let width = 1920;
                let height = 740;
                let _URL = window.URL || window.webkitURL;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file.raw);
            }).then(
                () => {
                    return file.raw;
                },
                () => {
                    this.$message.warning('上传图片尺寸只能是 1920*740px !');
                    // return Promise.reject();
                }
            );
        },
        // 处理修改按钮
        handleModifyProcessBtn(item) {
            item.isProcessModify = true;
        },
        // 处理保存按钮
        handleSaveProcessBtn(item) {
            let ref = item.formRef;
            this.$refs[ref].validate(valid => {
                if (valid) {
                    let formData = new FormData();
                    formData.append('id', item.id);
                    formData.append('main_title', item.title);
                    formData.append('sub_title', item.subtitle);
                    formData.append('jump_link', item.link);
                    formData.append('sort', item.sort);
                    formData.append('is_enable', item.status);
                    formData.append('picture', item.fileList);
                    // 打印formdata，不能直接用console.log打印
                    // formData.forEach((value, key) => {
                    //     console.log('key %s: value %s', key, value);
                    // });
                    mainBannerSaveModifyAction(formData)
                        .then(res => {
                            let { code, msg } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${msg}`,
                                    center: true
                                });
                                this.queryMainBannerData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.main-banner-page {
    box-sizing: border-box;
    // height: 100%;
    margin: 0 -20px 20px;
    background-color: #f7f8fa;

    &::after {
        clear: both;
        display: block;
        content: '';
        height: 0;
        visibility: hidden;
    }

    &::before {
        content: '';
        display: table;
    }

    .header-wrap {
        float: right;
        margin: 15px 35px 0 0;
    }

    :deep(.el-form) {
        margin-top: 60px;
    }

    :deep(.el-form:nth-of-type(n + 2)) {
        margin-top: 20px;
    }

    .content-wrap {
        &::after {
            clear: both;
            display: block;
            content: '';
            height: 0;
            visibility: hidden;
        }

        :deep(.el-form-item__content) {
            margin: 0 0 0 15px !important;
        }

        .avatar-uploader :deep(.el-upload) {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 380px;
            height: 150px;
            line-height: 150px;
            text-align: center;
        }

        .avatar {
            width: 380px;
            height: 150px;
            display: block;
        }

        .picture-desc {
            position: relative;
            left: 0;
            bottom: 15px;
        }

        .content-left {
            :deep(.el-input) {
                display: block;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .content-right {
            float: right;
            margin: 15px 15px 0 0;
            cursor: pointer;

            .size-icon {
                position: relative;
                top: 3px;
                font-size: 24px;
            }
        }
    }
}
</style>
